import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import infoDocument from "../../../img/icons/infoDocument.svg";
import remove from "../../../img/icons/Trash.svg";

import URL from "../../../config/default.json"

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";


const StocksForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const filePickerStocks = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);
    const [filenameStocks, setFilenameStocks] = useState<string>('');
    const [errorFileSizeStocks, setErrorStocks] = useState<boolean>(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const removeItem = () => {
        store.removeStocksItem(store.user.email, deleteId, deletePath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path:string) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeleteModal(true);
    }

    const fileLimit = URL.FILE_LIMIT;
    const [form_Data, set_Form_Data] = useState({
        typeOfGrain: '',
        countOfGrain: '',
        qualityOfGrain: ''
    })

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const typeOfGrain = form.elements.typeOfGrain.value;
            const countOfGrain = form.elements.countOfGrain.value;
            const qualityOfGrain = form.elements.qualityOfGrain.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadStocks = form.elements.uploadStocks.files[0];

            store.saveStocksInfo(store.user.email, store.user.id, typeOfGrain, countOfGrain, qualityOfGrain, dateAdded, uploadStocks);
            set_Validated(false);
            set_Form_Data({
                typeOfGrain: '',
                countOfGrain: '',
                qualityOfGrain: ''
            });
            setFilenameStocks('');
            if (filePickerStocks.current) {
                filePickerStocks.current.value = "";
            }
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const handlePickStocks = () => {
        if (filePickerStocks.current) {
            filePickerStocks.current.click();
        }
   }

   const uploadHandleChnageStocks = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorStocks(true);
            
            if (filePickerStocks.current) {
                filePickerStocks.current.value = "";
            }
        } else {
            setFilenameStocks(e.target.files[0].name);
            setErrorStocks(false);
        }
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-stocks-doc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        name="uploadStocks"
                        onChange={uploadHandleChnageStocks}
                        ref={filePickerStocks}
                    />
                    <div className="filePicker px-0 py-4">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <Button onClick={handlePickStocks}>Загрузить файл</Button>
                            <ModalDefault name="Заполнить автоматически через Росреестр" class="ms-5 btn-outline-wrap-gold"/>
                            <img src={infoDocument} alt="File Information"/>
                        </div>
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col lg={12}>
                                <div className="fileLable mt-3 text-center">{filenameStocks}
                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                </div>
                            </Col>
                            {errorFileSizeStocks  ? <Col lg={12}><Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert></Col> : ''}
                        </Row>
                    </div>
                </div>
                <CardTitle>Добавить запас</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationtypeOfGrain">
                            <FormLabel className='required'>Укажите сорт зерна</FormLabel>
                            <Form.Control
                                type="text"
                                name="typeOfGrain"
                                className='w-100 legal-form-1'
                                value={form_Data.typeOfGrain}
                                onChange={chngFn}
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationreprcountOfGrain">
                            <FormLabel className='required'>Укажите объем (тонн)</FormLabel>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="countOfGrain"
                                className='w-100 legal-form-1'
                                value={form_Data.countOfGrain}
                                onChange={chngFn}
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationqualityOfGrain">
                            <FormLabel className='required'>Добавьте описание качества урожая / сорта</FormLabel>
                            <Form.Control
                                as="textarea"
                                name="qualityOfGrain"
                                rows={6}
                                className='w-100 legal-form-1'
                                required
                                value={form_Data.qualityOfGrain}
                                onChange={chngFn}
                                placeholder='Введите краткое описание качества урожая / сорта'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>

            {store.stocksInfo && store.stocksInfo.stocks && store.stocksInfo.stocks.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные запасы</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.stocksInfo.stocks.map(function(data, idx){
                            const uploadStocksDBelemnt = data.uploadStocksPath;
                            const fileNameCopyScaDB = uploadStocksDBelemnt ? uploadStocksDBelemnt.split('-f-n-').pop() : '';
                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3">{data.dateAdded} {data.typeOfGrain}</div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{data.countOfGrain}</span><span>объем</span></div></Col>
                                        <Col lg={fileNameCopyScaDB ? 6 : 9}><div className="harvest-data-bottom-item"><span>Качества</span><span>{data.qualityOfGrain}</span></div></Col>
                                        
                                        {fileNameCopyScaDB ? <Col lg={3}><div className="harvest-data-bottom-item"><span>Загруженные файлы</span><span>{fileNameCopyScaDB}</span></div></Col> : ''}
                                        <Col lg={1}><div className="harvest-data-bottom-item remove">
                                        <Link to="" onClick={e => removeItemSetData(data._id, data.uploadStocksPath)}><img src={remove} alt="remove"/></Link></div></Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(StocksForm);