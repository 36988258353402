import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";

const HarvestDetail: FC = () => {
    const {store} = useContext(Context);
    function showHarvestItem(count:any, variant = '') {
        var harvest:any = store.harvestInfo.harvest;

        return (<>
            {harvest.map(function(data:any, idx:any){
                if (idx > count) {
                    return false;
                }
                
                return (
                    <div className="harvest-data p-0 pt-2" key={data._id + '-' + idx}>
                        <Row className="harvest-data-bottom">
                            <Col lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.volumen} т объем</span><span>{data.plantasName}</span></div></Col>
                            <Col lg={9}><div className="harvest-data-bottom-item-info"><span>Качества</span><span>{data.qualityDescription}</span></div></Col>
                        </Row>
                    </div>)
                }) 
            }
        </>);
    }
    return ( <>
     {store.isAuth && store.harvestInfo && store.harvestInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/harvest"><img className="me-3" src={selectFile} alt="Урожай"/>Урожай</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Ожидания">
                {showHarvestItem(2)}
            </Tab>
        </Tabs>
        
    </>) : <></>}
    </> );
}
 
export default observer(HarvestDetail);