import $api from "../http";
import {AxiosResponse} from 'axios';
import {StocksInfoResponse} from '../models/response/StocksInfoResponse';

export default class StocksInfoService {
    static async saveStocksInfoService(
        email: string,
        user: string,
        typeOfGrain: string,
        countOfGrain: string,
        qualityOfGrain: string,
        dateAdded: string,
        uploadStocks: File
        ): Promise<AxiosResponse<StocksInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('typeOfGrain', typeOfGrain);
        formData.append('countOfGrain', countOfGrain);
        formData.append('qualityOfGrain', qualityOfGrain);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadStocks', uploadStocks);

        return $api.post<StocksInfoResponse>('/saveStocks', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }

    static async removeStocksItem(
        email: string,
        itemId: string,
        uploadStocksPath: string,
        ): Promise<AxiosResponse<StocksInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('itemId', itemId);
        formData.append('uploadStocksPath', uploadStocksPath);

        return $api.post<StocksInfoResponse>('/removeStocksItem', {email, itemId, uploadStocksPath})
    }
}