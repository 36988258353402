import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import infoDocument from "../../../img/icons/infoDocument.svg";
import selectFile from "../../../img/icons/fileUpload.svg";
import ModalLink from "../../modals/ModalLink";
import URL from "../../../config/default.json"

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import remove from "../../../img/icons/Trash.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";

export const API_URL = URL.SERVER_URL_BASE;

const WarehouseForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const filePickerWarehouse = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);
    const [filenameWarehouse, setFilenameWarehouse] = useState<string>('');
    const [errorFileSizeWarehouse, setErrorWarehouse] = useState<boolean>(false);
    const purpose = ['хранение', 'сушка', 'склад'];

    const [imageWarehouseURL, setImageWarehouseURL] = useState<any>([]);
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [deletePhotoPath, setDeletePhotoPath] = useState([]);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const removeItem = () => {
        store.removeWarehouseItem(store.user.email, deleteId, deletePath, deletePhotoPath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path:string, photoPath: []) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeletePhotoPath(photoPath);
        setDeleteModal(true);
    }

    const fileLimit = URL.FILE_LIMIT;
    const fileLimitPhoto = URL.FILE_LIMIT_PHOTO;
    const photoAmountLimit = 5;
    const filePickerWarehousePhoto = useRef<HTMLInputElement>(null);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');
    const [errorLimitCount, setErrorLimitCount] = useState<boolean>(false);
    const [filenameWarehousePhoto, setFilenameConfirmDoc] = useState<any>(['Загрузить фотографию']);

    const [form_Data, set_Form_Data] = useState({
        usefulVolume: ''
    });

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfWarehouse1: true,
        isTypeOfWarehouse2: false,
        isTypeOfWarehouse3: false,
        isTypeOfWarehouse4: false,
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean, par4:boolean) {
        set_Form_Radio({
            isTypeOfWarehouse1: par1,
            isTypeOfWarehouse2: par2,
            isTypeOfWarehouse3: par3,
            isTypeOfWarehouse4: par4,
        })
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const isTypeOfWarehouse = form.elements.isTypeOfWarehouse.value;
            const specifyPurpose = form.elements.specifyPurpose.value;
            const usefulVolume = form.elements.usefulVolume.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadWarehouse = form.elements.uploadWarehouse.files[0];
            const uploadWarehousePhoto = form.elements.uploadWarehousePhoto.files;

            store.saveWarehouseInfo(store.user.email, store.user.id, isTypeOfWarehouse, specifyPurpose, usefulVolume, dateAdded, uploadWarehouse, uploadWarehousePhoto);
            set_Validated(false);

            set_Form_Data({
                usefulVolume: ''
            });

            setFilenameWarehouse('');
            setFilenameConfirmDoc(['Загрузить фотографию']);
            setImageWarehouseURL('');
            
            if (handleModal.current) {
                handleModal.current.click();
            }

            if (filePickerWarehousePhoto.current) {
                filePickerWarehousePhoto.current.value = "";
            }
        } else {
            set_Validated(true);
        }
        
    };

    const handlePickWarehouse = () => {
        if (filePickerWarehouse.current) {
            filePickerWarehouse.current.click();
        }
   }

   const uploadHandleChnageWarehouse = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorWarehouse(true);
            
            if (filePickerWarehouse.current) {
                filePickerWarehouse.current.value = "";
            }
        } else {
            setFilenameWarehouse(e.target.files[0].name);
            setErrorWarehouse(false);
        }
    }

    function readFileAsText(file:any){
        return new Promise(function(resolve,reject){
            let fr = new FileReader();
            fr.onload = function(){
                resolve(fr.result);
            };
            fr.onerror = function(){
                reject(fr);
            };
            fr.readAsDataURL(file);
        });
    }

    const uploadHandleChnageWarehousePhoto = (e: any) => {
        let photos = e.target.files;
        var photoArr: any[] = [];
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > photoAmountLimit) {
            setErrorLimitCount(true);
            if (filePickerWarehousePhoto.current) {
                filePickerWarehousePhoto.current.value = "";
            }
        } else {
            setErrorLimitCount(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimitPhoto) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                    photoArr.push(readFileAsText(photo));

                    setFilenameConfirmDoc(photoName);
                    Promise.all(photoArr).then((values) => {
                        console.log(values[0]);
                        setImageWarehouseURL(values);
                    });
                }
            });
            setErrorConfirmDoc(errOneOfFile);
        }
    }

    const handlePickWarehousePhoto = () => {
        if (filePickerWarehousePhoto.current) {
            filePickerWarehousePhoto.current.click();
        }
    }

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        name="uploadWarehouse"
                        onChange={uploadHandleChnageWarehouse}
                        ref={filePickerWarehouse}
                    />
                    <div className="filePicker px-0 py-4">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <Button onClick={handlePickWarehouse}>Загрузить файл</Button>
                            <ModalDefault name="Заполнить автоматически через Росреестр" class="ms-5 btn-outline-wrap-gold"/>
                            <img src={infoDocument} alt="File Information"/>
                        </div>
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col lg={12}>
                                <div className="fileLable mt-3 text-center">{filenameWarehouse}
                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                </div>
                            </Col>
                            {errorFileSizeWarehouse  ? <Col lg={12}><Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert></Col> : ''}
                        </Row>
                    </div>
                </div>
                <CardTitle>Добавить помещение</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3">
                            <FormLabel>Укажите тип помещения</FormLabel>
                            <Form.Check
                                type="radio"
                                name="isTypeOfWarehouse"
                                value="sklad"
                                label="Склад"
                                id="isTypeOfWarehouse1"
                                className="radio"
                                checked={form_Raio.isTypeOfWarehouse1}
                                onChange={e => radioChange(true, false, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfWarehouse"
                                value="lab"
                                label="Мехток или лаборатории"
                                id="isTypeOfWarehouse2"
                                className="radio"
                                checked={form_Raio.isTypeOfWarehouse2}
                                onChange={e => radioChange(false, true, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfWarehouse"
                                value="garage"
                                label="Гараж"
                                id="isTypeOfWarehouse3"
                                className="radio"
                                checked={form_Raio.isTypeOfWarehouse3}
                                onChange={e => radioChange(false, false, true, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfWarehouse"
                                value="marterroom"
                                label="Мастерская"
                                id="isTypeOfWarehouse4"
                                className="radio"
                                checked={form_Raio.isTypeOfWarehouse4}
                                onChange={e => radioChange(false, false, false, true)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel className='required'>Укажите назначение</FormLabel>
                            <Form.Select
                                name="specifyPurpose" 
                                className='w-100 legal-form-1'
                                required
                            >
                                <option value="">Выберите необходимое</option>
                                {purpose.map(function(data, idx){
                                    return  (<option key={'item' + idx } value={data}>{data}</option>);
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationCadastralNumber">
                            <FormLabel className='required'>Укажите полезный объем</FormLabel>
                            <Form.Control
                                type="text"
                                name="usefulVolume"
                                className='w-100 legal-form-1'
                                value={form_Data.usefulVolume}
                                onChange={chngFn}
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                            <Form.Control 
                                type="file"
                                className="hidden"
                                multiple
                                onChange={uploadHandleChnageWarehousePhoto}
                                accept=".jpg, .jpeg, .png, .heic"
                                name="uploadWarehousePhoto"
                                ref={filePickerWarehousePhoto}
                            />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickWarehousePhoto}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameWarehousePhoto.map(function (data:string, idx:any){return (<div key={'item'+idx} className="me-4">{data}</div>)})}
                                    <div className="small-txt">Формат файла должен соответствовать [.jpg, .jpeg, .png, .heic]</div>
                                </div>
                                
                                {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimitPhoto / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                {errorLimitCount ? <Alert className="d-flex w-100 mt-3" variant="danger">Количество файлов больше лимита в {photoAmountLimit} штук</Alert> : ''}
                            </div>
                            {imageWarehouseURL ? (
                                imageWarehouseURL.map(function(data:any, idx:any){
                                    return (<>
                                        <div key={'item'+idx} className="d-inline-block me-4"><div className="photo-land-img"><img src={data} alt=''/></div></div>
                                    </>);
                                })
                            ) : (<></>)}
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>

            {store.warehouseInfo && store.warehouseInfo.warehouse && store.warehouseInfo.warehouse.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные помещения</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.warehouseInfo.warehouse.map(function(data, idx){
                            const uploadWarehouseDBelemnt = data.uploadWarehousePath;
                            const fileNameCopyScaDB = uploadWarehouseDBelemnt ? uploadWarehouseDBelemnt.split('-f-n-').pop() : '';
                            let typeOfWarehouse = 'Склад';
                            if (data.isTypeOfWarehouse === 'lab') {
                                typeOfWarehouse = 'Мехток или лаборатории';
                            } else if (data.isTypeOfWarehouse === 'garage') {
                                typeOfWarehouse = 'Гараж';
                            } else if (data.isTypeOfWarehouse === 'marterroom') {
                                typeOfWarehouse = 'Мастерская';
                            }

                            
                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3">{typeOfWarehouse}</div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{data.usefulVolume} </span><span>полезный объем</span></div></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item"><span>Назначение</span><span>{data.specifyPurpose}</span></div></Col>
                                        {data.uploadWarehousePhotoPath ? (<>
                                        <Col lg={fileNameCopyScaDB ? 5 : 7}><div className="photo-technique-img loaded d-flex">
                                            {data.uploadWarehousePhotoPath.map(function (photo:any, idx:any){
                                                const uploadWarehousePhotoPath = photo ? photo.split('uploads/').pop() : '';
                                                return (<div key={'item'+idx}><div className="d-flex"><img src={API_URL + uploadWarehousePhotoPath} alt={data.isTypeOfWarehouse} /><ModalLink action="remove" /></div></div>);
                                            })}
                                            </div>
                                        </Col>
                                        </>): (<></>)}
                                        {fileNameCopyScaDB ? <Col lg={2}><div className="harvest-data-bottom-item"><span>Загруженные файлы</span><span>{fileNameCopyScaDB}</span></div></Col> : ''}
                                        <Col lg={1}><div className="harvest-data-bottom-item remove">
                                        <Link to="" onClick={e => removeItemSetData(data._id, data.uploadWarehousePath, data.uploadWarehousePhotoPath)}><img src={remove} alt="remove"/></Link></div>
                                        </Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(WarehouseForm);