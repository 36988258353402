import $api from "../http";
import {AxiosResponse} from 'axios';
import {TransactionInfoResponse} from '../models/response/TransactionInfoResponse';

export default class TransactionInfoService {
    static async saveTransactionInfoService(
        email: string,
        user: string,
        numberAccountFrom: string,
        freeAmount: string,
        sendAmount: string,
        dateAdded: string,
        totalAmount: string
        ): Promise<AxiosResponse<TransactionInfoResponse>> {
        console.log(email);
        return $api.post<TransactionInfoResponse>('/saveTransaction',{email, user, numberAccountFrom, freeAmount, sendAmount, dateAdded, totalAmount})
    }
}