import React, {createContext} from 'react';
import { createRoot } from 'react-dom/client';
import Store from "./store/store";
import { RouterProvider } from 'react-router-dom';
import {routes} from "./routes/Routes";
interface State {
    store: Store,
}

export const store = new Store();

export const Context = createContext<State>({
    store,
})


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
<Context.Provider value={{
        store
    }}>
        <RouterProvider router={routes}/>
    </Context.Provider>
);
