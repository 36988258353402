import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import modalImage from "../../../../img/icons/settings-alt.svg";
import defaultImage from "../../../../img/default/sklad.png";

import URL from "../../../../config/default.json"
import ModalGos from "../../../modals/ModalGos";

export const API_URL = URL.SERVER_URL_BASE;

const WarehouseDetail: FC = () => {
    const {store} = useContext(Context);
    function showWarehouseItem(count:any, variant = '') {
        var warehouse:any = store.warehouseInfo.warehouse;
        var index = 1;
        return (<>
            <div className="harvest-data p-0 pt-2">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={10}><Row>
                        {warehouse.map(function(data:any, idx:any){
                            if (index > count) {
                                return false;
                            }
                            const uploadWarehousePhotoPathElem = data.uploadWarehousePhotoPath[0];
                            const uploadWarehousePhotoPath = uploadWarehousePhotoPathElem ? uploadWarehousePhotoPathElem.split('uploads/').pop() : '';

                            const imgUrl = uploadWarehousePhotoPath ? API_URL + uploadWarehousePhotoPath : defaultImage;
                            if (data.isTypeOfWarehouse === variant || variant === '') {
                                index++;
                                return (<Col lg={4}>
                                    <div className="harvest-data-bottom-item-info">
                                    {data.uploadWarehousePhotoPath ? (<>
                                        <div className="photo-technique-img loaded info d-flex position-relative"> <img src={imgUrl} alt={data.nameOfWarehouse} />
                                            <div className="develop-technique px-3">
                                                <div className="develop-technique-title">{data.specifyPurpose}</div>
                                                <div className="develop-technique-year mb-2">{data.usefulVolume}</div>
                                            </div>
                                            <ModalGos classImg="img" class="develop-settings" name="" image={modalImage}/>
                                        </div>
                                    </>): (<></>)}
                                    </div>
                                    </Col>)
                            } else {
                                return (<></>)
                            }
                        }) 
                    }
                    </Row></Col>
                    <Col order="1" lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{index - 1}</span><span>eдиниц</span></div></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.warehouseInfo && store.warehouseInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/warehouse"><img className="me-3" src={selectFile} alt="Инфраструктура"/>Инфраструктура</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Все">
                {showWarehouseItem(3)}
            </Tab>
            <Tab eventKey="step2" title="Склады">
                {showWarehouseItem(3, 'sklad')}
            </Tab>
            <Tab eventKey="step3" title="Мехтоки и лаборатории">
                {showWarehouseItem(3, 'lab')}
            </Tab>
            <Tab eventKey="step4" title="Гаражи">
                {showWarehouseItem(3, 'garage')}
            </Tab>
            <Tab eventKey="step5" title="Мастерские">
                {showWarehouseItem(3, 'marterroom')}
            </Tab>
        </Tabs>
        
    </>) : <></>}
    </> );
}
 
export default observer(WarehouseDetail);