import $api from "../http";
import {AxiosResponse} from 'axios';
import {HarvestInfoResponse} from '../models/response/HarvestInfoResponse';

export default class HarvestInfoService {
    static async saveHarvestInfoService(
        email: string,
        user: string,
        plantasName: string,
        volumen: string,
        qualityDescription: string,
        dateAdded: string,
        uploadHarvest: File
        ): Promise<AxiosResponse<HarvestInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('plantasName', plantasName);
        formData.append('volumen', volumen);
        formData.append('qualityDescription', qualityDescription);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadHarvest', uploadHarvest);

        return $api.post<HarvestInfoResponse>('/saveHarvest', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }

    static async removeHarvestItem(
        email: string,
        itemId: string,
        uploadHarvestPath: string,
        ): Promise<AxiosResponse<HarvestInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('itemId', itemId);
        formData.append('uploadHarvestPath', uploadHarvestPath);

        return $api.post<HarvestInfoResponse>('/removeHarvestItem', {email, itemId, uploadHarvestPath})
    }
}