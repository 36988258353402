import {FC, useContext, useState, useRef} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import { Link } from 'react-router-dom';
import { Button,  CardTitle, Col, Container, Form,  Row, Tab, Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import envelope from "../../img/icons/Envelope.svg";
import RepresentativeForm1 from './representativeForms/representativeForm1';
import RepresentativeForm2 from './representativeForms/representativeForm2';
import OrgAccountSubMenu from './parts/OrgAccountSubMenu';

const AccountRepresentativeForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    let stepActiveItem:string = 'step1'
    let step2ActiveBtn:boolean = true;

    if (store.generelInfo.email) {
        stepActiveItem = 'step2'
        step2ActiveBtn = false;
    }

    const [key, setKey] = useState<any>(stepActiveItem);
    const [disableBtn2, setActive2] = useState<boolean>(step2ActiveBtn);

    const [show, setShow] = useState(false);
    const modalCompleateHendle = useRef<HTMLAnchorElement>(null);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const submitFn = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const confirmName = form.elements.confirmName.value;
            const confirmNumber = form.elements.confirmNumber.value;
            const dateAccessStart = form.elements.dateAccessStart.value;
            const dateAccessEnd = form.elements.dateAccessEnd.value;
            const uploadConfirmDoc = form.elements.uploadConfirmDoc.files[0];
            store.saveConfirmInfo(store.user.email, store.user.id, confirmName, confirmNumber, dateAccessStart, dateAccessEnd, uploadConfirmDoc);
           
           if (modalCompleateHendle.current) {
                modalCompleateHendle.current.click();
            }
        }
        set_Validated(true);
        
    };

    const NextStep = (e: any) => {
        const form = e.currentTarget.closest('form');
        if (form.checkValidity() === true) {
            setKey(e.target.id);
            if (e.target.id === 'step2') {
                const reprFio = form.elements.reprFio.value;
                const repName = form.elements.repName.value;
                const reprPatronimic = form.elements.reprPatronimic.value;
                const dateDateBirthday = form.elements.dateDateBirthday.value;
                const reprPhone = form.elements.reprPhone.value;
                const reprEmail = form.elements.reprEmail.value;
                const reprInn = form.elements.reprInn.value;
                const reprSNILS = form.elements.reprSNILS.value;
                const reprBirthdayPlace = form.elements.reprBirthdayPlace.value;
                const reprNationality = form.elements.reprNationality.value;
                const reprPasport = form.elements.reprPasport.value;
                const reprSerial = form.elements.reprSerial.value;
                const reprPasportNumber = form.elements.reprPasportNumber.value;
                const pasportDate = form.elements.pasportDate.value;
                const reprCodeDepartment = form.elements.reprCodeDepartment.value;
                const reprIssuedBy = form.elements.reprIssuedBy.value;
                const uploadPasportDoc = form.elements.uploadPasportDoc.files[0];
                const reprRegRegion = form.elements.reprRegRegion.value;
                const reprRegCity = form.elements.reprRegCity.value;
                const reprRegStreet = form.elements.reprRegStreet.value;
                const reprRegHouse = form.elements.reprRegHouse.value;
                const reprRegCorpus = form.elements.reprRegCorpus.value;
                const reprRegApp = form.elements.reprRegApp.value;
                const reprPostalCode = form.elements.reprPostalCode.value;
                const uploadRegistrationDoc = form.elements.uploadRegistrationDoc.files[0];
                const isLiveAddSameAsReg = form.isLiveAddSameAsReg.value;
                const isPostAddSameAsReg = form.isPostAddSameAsReg.value;
                const addrLiveRegion = form.addrLiveRegion.value;
                const addrLiveCity = form.addrLiveCity.value;
                const addrLiveStreet = form.addrLiveStreet.value;
                const addrLiveHouse = form.addrLiveHouse.value;
                const addrLiveCorpus = form.addrLiveCorpus.value;
                const addrLiveApp = form.addrLiveApp.value;
                const addrLivePostalCode = form.addrLivePostalCode.value;
                const postLiveRegion = form.postLiveRegion.value;
                const postLiveCity = form.postLiveCity.value;
                const postLiveStreet = form.postLiveStreet.value;
                const postLiveHouse = form.postLiveHouse.value;
                const postLiveCorpus = form.postLiveCorpus.value;
                const postLiveApp = form.postLiveApp.value;
                const postLivePostalCode = form.postLivePostalCode.value;
                
                store.saveGenerelInfo(store.user.email, store.user.id, reprFio, repName, reprPatronimic, dateDateBirthday, reprPhone, reprEmail, reprInn, reprSNILS, reprBirthdayPlace, reprNationality, reprPasport, reprSerial, reprPasportNumber, pasportDate, reprCodeDepartment, reprIssuedBy, uploadPasportDoc, reprRegRegion, reprRegCity, reprRegStreet, reprRegHouse, reprRegCorpus, reprRegApp, reprPostalCode, uploadRegistrationDoc, isLiveAddSameAsReg, isPostAddSameAsReg, addrLiveRegion, addrLiveCity, addrLiveStreet, addrLiveHouse, addrLiveCorpus, addrLiveApp, addrLivePostalCode, postLiveRegion, postLiveCity, postLiveStreet, postLiveHouse, postLiveCorpus, postLiveApp, postLivePostalCode);
                setActive2(false);
            }
            set_Validated(false);
        } else {
            set_Validated(true);
        }
    }

    return (<>
        {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4"/>
        <Container className='mb-5'>
            <CardTitle className='mt-5'>Анкета пользователя представителя Юридического лица</CardTitle>
            <Tabs 
                defaultActiveKey={stepActiveItem}
                variant="underline"
                id="uncontrolled-tab-example" className="mb-3" justify
                activeKey={key}
                onSelect={(k) => setKey(k)}
                >
                <Tab eventKey="step1" title="Шаг">
                    <Form noValidate validated={validated}>
                        <RepresentativeForm1/>
                        <Button className='mt-5' variant="secondary" id="step2" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step2" title="Шаг" disabled={disableBtn2}>
                    <Form noValidate validated={validated} onSubmit={submitFn}>
                        <RepresentativeForm2/>
                        <Row className='mt-5'><Col><Button variant="primary" id="form-submit" type="submit">Отправить на проверку</Button></Col></Row>
                    </Form>
                    <Link to="/" ref={modalCompleateHendle} onClick={handleShow}></Link>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Форма отправлена на проверку и будет обработана в ближайшее время</h4>
                            <Link to="/account" className="btn btn-secondary mt-4">Перейти на главную страницу личного кабинета для заполнения Анкета Юридического лица </Link>
                        </Modal.Body>
                        <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Tab>
            </Tabs>
        </Container>
        </>) : <></>}
    </>);
}
 
export default observer(AccountRepresentativeForm);