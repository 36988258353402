import {FC, useContext, useEffect} from 'react';
import {Outlet} from "react-router-dom";
import {observer} from "mobx-react-lite";

import {Context} from "./index";

import './styles/custom.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

const App: FC = () => {
    const {store} = useContext(Context);

    useEffect(() => {
        if (localStorage.getItem('token') && (localStorage.getItem('token') !== 'undefined')) {
            store.checkAuth()
        }
    }, [store])

    if (store.isLoading) {
        return  <div className="download loader"></div>
    }
      return (
        <div className="App">
            <div className="App-body">
                <Header/>
                <main>
                      <Outlet/>
                </main>
                <Footer/>
            </div>
      </div>
      );
};

export default observer(App);