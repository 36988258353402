import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import envelope from "../../img/icons/Envelope.svg";
import remove from "../../img/icons/Trash.svg";
import infoSuccess from "../../img/icons/infoSuccess.svg";
import infoDownload from "../../img/icons/infoDownload.svg";
import infoEdit from "../../img/icons/infoEdit.svg";
import infoDocument from "../../img/icons/infoDocument.svg";

function ModalLink(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {e.preventDefault(); setShow(true)};

  return (
    <>
    
      <Link className={props.class} onClick={handleShow}>
        {props.action === 'remove' ? (<img src={remove} alt="remove"/>) : ''}
        {props.action === 'success' ? (<img src={infoSuccess} className="me-2" alt="remove"/>) : ''}
        {props.action === 'download' ? (<img src={infoDownload} className="me-2" alt="remove"/>) : ''}
        {props.action === 'edit' ? (<img src={infoEdit} alt="remove"/>) : ''}
        {props.action === 'info' ? (<img src={infoDocument} alt="remove"/>) : ''}
        {props.name ? props.name : ''}
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4>Совсем скоро будут доступны все возможности нашей платформы!</h4>
          <p>Но пока данное окно находится в разработке</p>
        </Modal.Body>
        <Modal.Footer>
          <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
          <div className='btn-outline-wrap-green mt-3 mb-4'>
          <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLink;