import $api from "../http";
import {AxiosResponse} from 'axios';
import {TechniqueInfoResponse} from '../models/response/TechniqueInfoResponse';

export default class TechniqueInfoService {
    static async saveTechniqueInfoService(
        email: string,
        user: string,
        isTypeOfTechnique: string,
        nameOfTechnique: string,
        yearOfTechnique: string,
        stsOfTechnique: string,
        dateAdded: string,
        uploadTechnique: File,
        uploadTechniquePhoto: any
        ): Promise<AxiosResponse<TechniqueInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('isTypeOfTechnique', isTypeOfTechnique);
        formData.append('nameOfTechnique', nameOfTechnique);
        formData.append('yearOfTechnique', yearOfTechnique);
        formData.append('stsOfTechnique', stsOfTechnique);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadTechnique', uploadTechnique);


        for (let index = 0; index < uploadTechniquePhoto.length; index++) {
            const photo = uploadTechniquePhoto[index];
            formData.append('uploadTechniquePhoto', photo);
        }
        

        return $api.post<TechniqueInfoResponse>('/saveTechnique', formData, {
            headers: {
                'enctype': 'multipart/form-data'
            }
        })
    }
    static async removeTechniqueItem(
        email: string,
        itemId: string,
        uploadTechniquePath: string,
        uploadTechniquePhotoPath: [],
        ): Promise<AxiosResponse<TechniqueInfoResponse>> {

        return $api.post<TechniqueInfoResponse>('/removeTechniqueItem', {email, itemId, uploadTechniquePath, uploadTechniquePhotoPath})
    }
}