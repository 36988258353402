import $api from "../http";
import {AxiosResponse} from 'axios';
import {InfoMainResponse} from '../models/response/InfoMainResponse';

export default class InfoMainService {
    static async saveInfoMain(
        email: string,
        user: string,
        infoDescription: string,
        infoDistrict: string,
        uploadInfoMain: File
        ): Promise<AxiosResponse<InfoMainResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('infoDescription', infoDescription);
        formData.append('infoDistrict', infoDistrict);
        formData.append('uploadInfoMain', uploadInfoMain);

        return $api.post<InfoMainResponse>('/saveMainInfo', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}