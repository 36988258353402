import $api from "../http";
import {AxiosResponse} from 'axios';
import {LandInfoResponse} from '../models/response/LandInfoResponse';

export default class LandInfoService {
    static async saveLandInfoService(
        email: string,
        user: string,
        cadastralNumber: string,
        area: string,
        isOwnership: string,
        dateAdded: string,
        uploadLand: File,
        uploadLandPhoto: File
        ): Promise<AxiosResponse<LandInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('cadastralNumber', cadastralNumber);
        formData.append('area', area);
        formData.append('isOwnership', isOwnership);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadLand', uploadLand);
        formData.append('uploadLandPhoto', uploadLandPhoto);

        return $api.post<LandInfoResponse>('/saveLand', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }

    static async removeLandItem(
        email: string,
        itemId: string,
        uploadLandPath: string,
        uploadLandPhotoPath: string,
        ): Promise<AxiosResponse<LandInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('itemId', itemId);
        formData.append('uploadLandPath', uploadLandPath);
        formData.append('uploadLandPhotoPath', uploadLandPhotoPath);

        return $api.post<LandInfoResponse>('/removeLandItem', {email, itemId, uploadLandPath, uploadLandPhotoPath})
    }
}