import $api from "../http";
import {AxiosResponse} from 'axios';
import {WarehouseInfoResponse} from '../models/response/WarehouseInfoResponse';

export default class WarehouseInfoService {
    static async saveWarehouseInfoService(
        email: string,
        user: string,
        isTypeOfWarehouse: string,
        specifyPurpose: string,
        usefulVolume: string,
        dateAdded: string,
        uploadWarehouse: File,
        uploadWarehousePhoto: any
        ): Promise<AxiosResponse<WarehouseInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('isTypeOfWarehouse', isTypeOfWarehouse);
        formData.append('specifyPurpose', specifyPurpose);
        formData.append('usefulVolume', usefulVolume);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadWarehouse', uploadWarehouse);


        for (let index = 0; index < uploadWarehousePhoto.length; index++) {
            const photo = uploadWarehousePhoto[index];
            formData.append('uploadWarehousePhoto', photo);
        }
        

        return $api.post<WarehouseInfoResponse>('/saveWarehouse', formData, {
            headers: {
                'enctype': 'multipart/form-data'
            }
        })
    }

    static async removeWarehouseItem(
        email: string,
        itemId: string,
        uploadWarehousePath: string,
        uploadWarehousePhotoPath: [],
        ): Promise<AxiosResponse<WarehouseInfoResponse>> {

        return $api.post<WarehouseInfoResponse>('/removeWarehouseItem', {email, itemId, uploadWarehousePath, uploadWarehousePhotoPath})
    }
    
}