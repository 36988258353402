import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";

import URL from "../../../../config/default.json"
import ModalDefault from "../../../modals/ModalDefault";

export const API_URL = URL.SERVER_URL_BASE;

const LandDetail: FC = () => {
    const {store} = useContext(Context);
    function showLandItem(count:any, variant = '') {
        var land:any = store.landInfo.land;
        var index = 1;
        return (<>
            {land.map(function(data:any, idx:any){
                if (index > count) {
                    return false;
                }
                const uploadLandPhotoPathElem = data.uploadLandPhotoPath;
                const uploadLandPhotoPath = uploadLandPhotoPathElem ? uploadLandPhotoPathElem.split('uploads/').pop() : '';
                if (data.isOwnership === variant || variant === '') {
                    index++;
                    return (<>
                        <div className="harvest-data p-0 pt-2" key={idx}>
                            <Row className="harvest-data-bottom">
                                <Col lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.area} га</span><span>площадь участка</span></div></Col>
                                <Col lg={10}><div className="harvest-data-bottom-item-info">
                                    {data.uploadLandPhotoPath ? (<>
                                        <div className="photo-technique-img loaded info d-flex"> <img src={API_URL + uploadLandPhotoPath} alt={data.cadastralNumber} />
                                            <div className="develop-area">
                                                <div className="develop-area-title mb-3">Обработка посевов</div>
                                                <ModalDefault name="Отобразить пестициды" classButton="mb-3 d-block w-100" variant="primary"/>
                                                <ModalDefault name="Отобразить агрохимикаты" classButton="d-block" variant="primary"/>
                                            </div>
                                        </div>
                                    </>): (<></>)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </>)
                    } else {
                        return (<></>)
                    }
                }) 
            }
        </>);
    }
    return ( <>
     {store.isAuth && store.landInfo && store.landInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/land"><img className="me-3" src={selectFile} alt="Земля"/>Земля</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Все">
                {showLandItem(3)}
            </Tab>
            <Tab eventKey="step2" title="В собственности">
                {showLandItem(3, 'property')}
            </Tab>
            <Tab eventKey="step3" title="В аренде">
                {showLandItem(3, 'rent')}
            </Tab>
        </Tabs>
        
    </>) : <></>}
    </> );
}
 
export default observer(LandDetail);