import React from "react";
import ModalGos from "../../modals/ModalGos";
import baige from "../../../img/icons/gosAchiv.svg";

const LoadData = () => {
    return ( <div className="load-data">
        <div className="load-data-or-label">ИЛИ</div>
        <div className="load-data-title">Заполнить все автоматически:</div>
        <ModalGos class="load-data-link" name="Загрузить данные из ФГИС “Зерно”"/>
        <ModalGos class="load-data-link" name="Загрузить данные из ФГИС “Сатурн”"/>
        <ModalGos class="load-data-link" name="Загрузить данные из Росреестра"/>
        <div className="load-data-sub-text">и получить бейдж верифицированных данных</div>
        <div className="load-data-achive"><img src={baige} alt="бейдж верифицированных данных"/></div>
    </div> );
}
 
export default LoadData;