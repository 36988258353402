import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tooltipimg from "../../img/icons/Info-circle.svg";
import TooltipGreen from "../../img/icons/Info-circle-green.svg";

function TooltipInfo(prop) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {prop.info ? prop.info : 'Информация'}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="link"><img src={prop.img === "green" ? TooltipGreen : Tooltipimg} alt="tooltipe"/></Button>
    </OverlayTrigger>
  );
}

export default TooltipInfo;