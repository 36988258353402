import React, { useRef, useState } from "react";
import { FC, useContext } from "react";
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {Context} from "../../index";
import HarvestDetail from "./informationForms/infoDetail/harvestDetail";
import LandDetail from "./informationForms/infoDetail/landDetail";
import StocksDetail from "./informationForms/infoDetail/stocksDetail";
import TechniqueDetail from "./informationForms/infoDetail/techniqueDetail";
import WarehouseDetail from "./informationForms/infoDetail/warehouseDetail";
import AccountMenu from "./parts/AccountMenu";
import LoadData from "./parts/LoadData";
import ModalLink from "../modals/ModalLink";
import URL from "../../config/default.json"

import infoDocument from "../../img/icons/infoDocument.svg";
import selectFile from "../../img/icons/fileUpload.svg";
import envelope from "../../img/icons/Envelope.svg";

const AccountNewInformation: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const fileLimit = URL.FILE_LIMIT;
    const maxLength = 7000;

    // Сconfirmation message
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};
    const handleModal = useRef<HTMLAnchorElement>(null);

    const [textAreaCount, setCounterData] = useState<number>(0);

    const uploadInformationDocDBelemnt = store.infoMain.uploadInfoMainPath;
    const [errorFileSizeInformationDoc, setErrorInformationDoc] = useState<boolean>(false);
    const filePickerLicenseInformationDoc = useRef<HTMLInputElement>(null);
    const [filenameInformationDoc, setFilenameInformationDoc] = useState<string>('');

    const [form_Data, set_Form_Data] = useState({
        email: store.infoMain.email,
        infoDescription: store.infoMain.infoDescription,
        infoDistrict: store.infoMain.infoDistrict,
        uploadInfoMainPath: uploadInformationDocDBelemnt
    });
    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
        if (name === 'infoDescription') {
            setCounterData(e.target.value.length);
        }
    };

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const infoDescription = form.elements.infoDescription.value;
            const infoDistrict = form.elements.infoDistrict.value;
            const uploadInfoMain = form.elements.uploadInfoMain.files[0];
            store.saveInfoMain(store.user.email, store.user.id, infoDescription, infoDistrict, uploadInfoMain);
            setFilenameInformationDoc('');
            if (handleModal.current) {
                handleModal.current.click();
            }
            if (filePickerLicenseInformationDoc.current) {
                filePickerLicenseInformationDoc.current.value = "";
            }
        }
        set_Validated(true);
        
    };

    const uploadHandleChnageInformationDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorInformationDoc(true);
            
            if (filePickerLicenseInformationDoc.current) {
                filePickerLicenseInformationDoc.current.value = "";
            }
        } else {
            setFilenameInformationDoc(e.target.files[0].name);
            setErrorInformationDoc(false);
        }
    }

    const handlePickInformationDoc = () => {
        if (filePickerLicenseInformationDoc.current) {
            filePickerLicenseInformationDoc.current.click();
        }
    }

    return ( <>
        <AccountMenu/>
        <Form noValidate validated={validated} onSubmit={submitForm}>
            <Row>
                <Col lg={8}>
                    <CardTitle>Общая информация</CardTitle>
                    <Card className="mb-3">
                        <CardBody>
                            <Form.Group as={Col} className="mb-3 position-relative" controlId="validationReprRegRegion">
                                <FormLabel>Описание</FormLabel>
                                <Form.Control
                                    as="textarea"
                                    name="infoDescription"
                                    rows={10}
                                    value={form_Data.infoDescription}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Введите краткое описание своего производства, специфику и предложение'
                                    maxLength={maxLength}
                                />
                                <div className="text-counter">{textAreaCount} / {maxLength}</div>
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="validationReprRegRegion">
                                <FormLabel>Регион деятельности</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="infoDistrict"
                                    value={form_Data.infoDistrict}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Введите регион деятельности'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <CardTitle>Открытые документы</CardTitle>
                    <Card className="mb-3">
                        <CardBody className="card-document">
                                {store.infoMain.doc !== undefined && store.infoMain.doc.length > 0 ? (<>
                                <div className="card-document-links filled">
                                    <Row>
                                    {store.infoMain.doc.map(function(data, idx){
                                        const uploadInfoMainPathDBelemnt = data.uploadInfoMainPath;
                                        const fileNameCopyScaDB = uploadInfoMainPathDBelemnt ? uploadInfoMainPathDBelemnt.split('-f-n-').pop() : '';
                                        return (
                                                <Col lg={6} key={idx} className="select-document mb-4">
                                                    <div className="select-document-wrap">
                                                        {fileNameCopyScaDB}

                                                        <div className="align-self-end mt-5 d-flex justify-content-between">
                                                            <div>
                                                                <ModalLink action="success" />
                                                                <ModalLink action="download" />
                                                                <ModalLink action="edit" />
                                                            </div>
                                                            <div>
                                                                <ModalLink action="info" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }) }
                                        <Col lg={6} className={store.infoMain.doc !== undefined && store.infoMain.doc.length < 4 ? 'select-document' : 'hidden'}>
                                            <div className="select-document-wrap">
                                                <div className="gray-text mb-5">Добавить другие документы</div>
                                                <div className="align-self-end d-flex justify-space-beetwen">
                                                    <Form.Group as={Col} controlId="validationLicenseInformationDoc">
                                                        <Form.Control 
                                                            type="file"
                                                            className="hidden"
                                                            onChange={uploadHandleChnageInformationDoc}
                                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                            name="uploadInfoMain"
                                                            ref={filePickerLicenseInformationDoc}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className=" ">
                                                                <div className="me-4 fileIcon" onClick={handlePickInformationDoc}><img src={selectFile} alt="Select file"/></div>
                                                            </div>
                                                            
                                                            <div className="card-document-link">
                                                                <Link to="/"><img src={infoDocument} alt="File Information"/></Link>
                                                            </div>
                                                        </div>
                                                        <div className="fileLable">{filenameInformationDoc}</div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                        {errorFileSizeInformationDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        {store.infoMain.doc !== undefined && store.infoMain.doc.length === 4 ? (
                                            <Alert className="d-flex w-100 m-0 py-0 text-small" variant="warning">Максимальное количество файлов добавлено</Alert>
                                        ) : (<div className="small-txt small-size mt-2">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>)}
                                        </Col>
                                        
                                    </Row>
                                    </div>
                                    </>) : (
                                        <>
                                        <span className="card-document-title">Добавить документ</span>
                                        <div className="card-document-links">
                                            <div className="card-document-link">
                                                <Form.Group as={Col} controlId="validationLicenseInformationDoc">
                                                    <Form.Control 
                                                        type="file"
                                                        className="hidden"
                                                        onChange={uploadHandleChnageInformationDoc}
                                                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                        name="uploadInfoMain"
                                                        ref={filePickerLicenseInformationDoc}
                                                    />
                                                    
                                                    <div className="filePicker d-flex align-items-center">
                                                        <div className="me-4 fileIcon" onClick={handlePickInformationDoc}><img src={selectFile} alt="Select file"/></div>
                                                        <div className="fileLable">{filenameInformationDoc}
                                                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                                        </div>
                                                        
                                                        {errorFileSizeInformationDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="card-document-link">
                                                <Link to="/"><img src={infoDocument} alt="File Information"/></Link>
                                            </div>
                                        </div>
                                        </>
                                    )}
                            
                        </CardBody>
                    </Card>
                </Col>
                <Col><Button variant="secondary" id="form-submit" type="submit">Сохранить</Button></Col>
            </Row>
            <Link to="/" onClick={handleShow} ref={handleModal}></Link>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <h4>Даные добавлены</h4>
                <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                </Modal.Body>
                <Modal.Footer>
                <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                <div className='btn-outline-wrap-green mt-3 mb-4'>
                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Form>
        <CardTitle className="mt-5">Урожай и посевные площади</CardTitle>
        <Card>
            <CardBody>
                <Row className="align-items-center">
                    {store.harvestInfo.email && store.harvestInfo.harvest && store.harvestInfo.harvest.length > 0 ? (
                        <Col lg={12}>
                            <div className="card-link-container mb-4">
                                <HarvestDetail />
                            </div>
                        </Col>) : (<></>)}
                    {store.landInfo.email && store.landInfo.land && store.landInfo.land.length > 0 ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <LandDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.harvestInfo.email && store.landInfo.email && store.harvestInfo.harvest && store.harvestInfo.harvest.length > 0 && store.landInfo.land && store.landInfo.land.length > 0 ? (<></>) : (<>
                        <Col lg={8}>
                            {store.harvestInfo.email && store.harvestInfo.harvest.length > 0  ? (<></>):(<div className="card-link-container mb-4"><Link to="/harvest"><img className="me-3" src={selectFile} alt="Добавить урожай и посевную площадь"/> Добавить урожай и посевную площадь</Link></div>)}
                            {store.landInfo.email && store.landInfo.land && store.landInfo.land.length > 0 ? (<></>) : (
                                <div className="card-link-container">
                                    <Link to="/land"> <img className="me-3" src={selectFile} alt="Добавить землю"/>Добавить землю</Link>
                                </div>
                            )}
                        </Col>
                        <Col lg={4}>
                            <LoadData/>
                        </Col>
                    </>)}
                </Row>
            </CardBody>
        </Card>
        <CardTitle className="mt-5">Техника и инфраструктура</CardTitle>
        <Card>
            <CardBody>
                <Row className="align-items-center">
                    {store.techniqueInfo.email && store.techniqueInfo.technique && store.techniqueInfo.technique.length > 0 ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <TechniqueDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.warehouseInfo.email && store.warehouseInfo.warehouse && store.warehouseInfo.warehouse.length > 0 ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <WarehouseDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.stocksInfo.email && store.stocksInfo.stocks && store.stocksInfo.stocks.length > 0  ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <StocksDetail />
                        </div>
                    </Col>) : (<></>)}
                    

                    <Col lg={8}>
                        {store.techniqueInfo.email && store.techniqueInfo.technique && store.techniqueInfo.technique.length > 0 ? (<></>) : (<div className="card-link-container mb-4">
                            <Link to="/technique"><img className="me-3" src={selectFile} alt="Добавить технику"/> Добавить технику</Link>
                        </div>)}
                        {store.warehouseInfo.email && store.warehouseInfo.warehouse && store.warehouseInfo.warehouse.length > 0 ? (<></>) : (<div className="card-link-container mb-4">
                            <Link to="/warehouse"><img className="me-3" src={selectFile} alt="Добавить помещение"/> Добавить помещение</Link>
                            </div>)}
                        {store.stocksInfo.email && store.stocksInfo.stocks && store.stocksInfo.stocks.length > 0 ? (<></>) : (<div className="card-link-container">
                            <Link to="/stocks"> <img className="me-3" src={selectFile} alt="Добавить складские запасы"/> Добавить складские запасы</Link>
                        </div>)}
                    </Col>
                    {store.techniqueInfo.email && store.techniqueInfo.technique && store.techniqueInfo.technique.length > 0 && store.warehouseInfo.email && store.warehouseInfo.warehouse && store.warehouseInfo.warehouse.length > 0 && store.stocksInfo.email && store.stocksInfo.stocks && store.stocksInfo.stocks.length > 0 ? (<></>) : (
                        <Col lg={4}>
                            <LoadData/>
                        </Col>
                    )}
                    
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default observer(AccountNewInformation);