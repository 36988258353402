import React, { useContext } from "react";
import ModalLink from "../../modals/ModalLink";
import TooltipInfo from "../../tooltips/TooltipInfo";

import {Context} from "../../../index";

import avatars from "../../../img/avatars.png";
import beidges from "../../../img/icons/beidges.png";


const AccountMenu = () => {
    const {store} = useContext(Context);
    return ( <>
        <div className="new-customer-title d-flex gap-4 py-4">
            <div className="new-customer-img"><img src={avatars} alt="Login page"/></div>
            <div className="new-customer-data">
                <h3 className="title">{store.user.companyName}</h3>
                <div><span className="rewards-number">00.00</span> рейтинг <TooltipInfo/></div>
            </div>
        </div>
        <div className="rewards d-flex gap-4 py-4">
            <div className="new-customer-img"><img src={beidges} alt="Beidges"/></div>
            <ModalLink class="rewards-link disabled" name="Пока нет наград"/>
        </div>
    </> );
}
 
export default AccountMenu;