import { FC, useContext, useState, useRef } from "react";
import {Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";

import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";

const RepresentativeForm2: FC = () => {
    const {store} = useContext(Context);
    const validated = false;
    const fileLimit = 10000000;

    // Data for документ подтверждающий полномочия представителя file
    const filePickerLicenseConfirmDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<boolean>(false);
    const [filenameConfirmDoc, setFilenameConfirmDoc] = useState<string>('Загрузить скан-копию документа');
    const uploadConfirmDocDBelemnt = store.confirmInfo.uploadConfirmDocPath;

    const [form_Data, set_Form_Data] = useState({
        email: store.confirmInfo.email,
        confirmName: store.confirmInfo.confirmName,
        confirmNumber: store.confirmInfo.confirmNumber,
        dateAccessStart: store.confirmInfo.dateAccessStart,
        dateAccessEnd: store.confirmInfo.dateAccessEnd,
        uploadConfirmDoc: store.confirmInfo.uploadConfirmDoc,
        uploadConfirmDocPath: uploadConfirmDocDBelemnt,
    });

    const fileNameCopyScaDB = uploadConfirmDocDBelemnt ? uploadConfirmDocDBelemnt.split('-f-n-').pop() : '';
    const requiredConfirmDoc:boolean = uploadConfirmDocDBelemnt ? false : true;

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const uploadHandleChnageConfirmDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorConfirmDoc(true);
            
            if (filePickerLicenseConfirmDoc.current) {
                filePickerLicenseConfirmDoc.current.value = "";
            }
        } else {
            setFilenameConfirmDoc(e.target.files[0].name);
            setErrorConfirmDoc(false);
        }
    }

    const handlePickConfirmDoc = () => {
        if (filePickerLicenseConfirmDoc.current) {
            filePickerLicenseConfirmDoc.current.click();
        }
    }

    return ( <>
        <CardText className="mt-5">Документ, подтверждающий полномочия представителя</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationConfirmName">
                    <FormLabel className='required'>Наименование</FormLabel>
                    <Form.Control
                        type="text"
                        name="confirmName"
                        value={form_Data.confirmName}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ']+$"
                        className='w-100 legal-form-1'
                        required
                        placeholder='Наименование'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ]+$/.test(form_Data.confirmName)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationConfirmNumber">
                        <FormLabel className='required'>Номер</FormLabel>
                        <Form.Control
                            type="text"
                            name="confirmNumber"
                            value={form_Data.confirmNumber}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Номер'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9- ]+$/.test(form_Data.confirmNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validatiodateAccessStart">
                        <FormLabel className='required'>Дата выдачи</FormLabel>
                        <Form.Control
                            type="date"
                            name="dateAccessStart"
                            onChange={chngFn}
                            max={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.dateAccessStart}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дата выдачи'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validatioAccessEnd">
                        <FormLabel className='required'>Срок действия</FormLabel>
                        <Form.Control
                            type="date"
                            name="dateAccessEnd"
                            onChange={chngFn}
                            min={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.dateAccessEnd}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Срок действия'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageConfirmDoc}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredConfirmDoc}
                        name="uploadConfirmDoc"
                        ref={filePickerLicenseConfirmDoc}
                        disabled={!requiredConfirmDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickConfirmDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameConfirmDoc}
                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameCopyScaDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameCopyScaDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeConfirmDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
        </Card>
    </> );
}
 
export default RepresentativeForm2;