import { FC, useContext } from "react";
import {Context} from "../../index";
import {Col, Container, Row } from "react-bootstrap";
import TooltipInfo from "../tooltips/TooltipInfo";
import ModalTemplates from "../modals/ModalTemplates";

import tooltipimg from "../../img/icons/Info-circle-white.svg";
import editWhite from "../../img/icons/Edit-white.svg";
import emitImage from "../../img/icons/mycfa-default.svg";
import checkingImage from "../../img/icons/checking.svg";
import doneImage from "../../img/icons/done.svg";
import cancelImage from "../../img/icons/cancel.svg";
import { Link } from "react-router-dom";

const ReleaseCfa: FC = () => {
    const {store} = useContext(Context);
    const companyName = store.company.shortCompanyName;
    function showCfaItem() {
        let releaseCfa:any = store.releaseCfaInfo.releaseCfa;
        let emitId:any = store.releaseCfaInfo.id;

        return (<>
            {releaseCfa ? releaseCfa.map(function(data:any, idx:any){
                let text = 'На проверке';
                let image = checkingImage;
                let classes = 'cfa-status confirmation';

                let status = data.status;
                if (status === 'declined') {
                    text = 'Отменен Платформой';
                    image = cancelImage;
                    classes = 'cfa-status cancel';
                } else if (status === 'approval' || status === 'OIS_approval') {
                    text = 'На согласовании';
                    image = checkingImage;
                    classes = 'cfa-status confirmation';
                } else if (status === 'approved') {
                    text = 'Согласован';
                    image = doneImage;
                    classes = 'cfa-status done';
                } else if (status === 'collecting_applications') {
                    text = 'Сбор Заявок';
                    image = checkingImage;
                    classes = 'cfa-status done';
                } else if (status === 'collecting_compleated') {
                    text = 'Закрыт сбор заявок';
                    image = doneImage;
                    classes = 'cfa-status done';
                } else if (status === 'active') {
                    text = 'Активен';
                    image = doneImage;
                    classes = 'cfa-status done';
                } else if (status === 'redeem_started') {
                    text = 'В процессе погашения';
                    image = doneImage;
                    classes = 'cfa-status done';
                } else if (status === 'redeemed') {
                    text = 'Погашен';
                    image = doneImage;
                    classes = 'cfa-status done';
                }
                return (
                    <Row key={idx} className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                        <Col className="ps-0" lg={4}>
                            <a className="detail-cfa-link" href={`/releaseCfaDetail/`+ emitId + `/` + data._id}>
                                <div className='cfa-table-title'>
                                    <div className='cfa-table-sub-title'>Digital-Grain_{data.ticker}</div>
                                    <div className='cfa-table-name'>{data.releaseName} </div>
                                </div>
                            </a>
                        </Col>
                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{companyName || data.emitName}</span></Col>
                        <Col className="text-center" lg={1}>{data.countCfa}</Col>
                        <Col className="text-end" lg={1}>{data.dateCompleateCfa}</Col>
                        <Col className="text-center cfa-price" lg={2}>{(Number(data.countCfa) * Number(data.priceCompleateCfa)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</Col>
                        <Col className="text-center" lg={2}>
                            <div className={classes}>
                                <img src={image} alt=""/>
                                <span>{text}</span>
                                <img width="20" src={tooltipimg} alt=""/>
                            </div>
                        </Col>
                    </Row>
                    )
                }) : (<></>)
            }
        </>);
    }

    return ( <>
        {store.isAuth && store.user.isActivated ? (<>
            <div className='organization-links'>
                <Container>
                    <div className='text-end'>
                        <TooltipInfo info="Информация ЦФА" img="green" />
                    </div>
                </Container>
            </div>
            <Container>
                <h2 className="cfa-gold-header mb-4 mt-5">Мои выпуски ЦФА:</h2>
                <Row className="cfa-table-header me-0 ms-0">
                    <Col lg={4}>Наименование</Col>
                    <Col lg={2}>Эмитент</Col>
                    <Col className="text-center" lg={1}>Количество</Col>
                    <Col className="text-end" lg={1}>Дата погашения</Col>
                    <Col className="text-center" lg={2}>Цена погашения</Col>
                    <Col className="text-center" lg={2}>Статус</Col>
                </Row>
                {store.releaseCfaInfo && store.releaseCfaInfo.emitName ? (
                    <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                        <Col className="ps-0" lg={4}>
                            <div className='cfa-table-title'>
                                <div className='cfa-table-sub-title'>Digital-Grain_{store.releaseCfaInfo.ticker}</div>
                                <div className='cfa-table-name'>{store.releaseCfaInfo.emitName}</div>
                            </div>
                        </Col>
                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{store.releaseCfaInfo.emitName}</span></Col>
                        <Col className="text-center" lg={1}>{store.releaseCfaInfo.countCfa}</Col>
                        <Col className="text-end" lg={1}>{store.releaseCfaInfo.dateCompleateCfa}</Col>
                        <Col className="text-center cfa-price" lg={2}>{(Number(store.releaseCfaInfo.countCfa) * Number(store.releaseCfaInfo.priceCompleateCfa)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</Col>
                        <Col className="text-center" lg={2}>
                            <div className="cfa-status">
                                <Link to="/base-template" ><img src={editWhite} alt=""/></Link>
                                <span>ЧЕРНОВИК</span>
                                <img width="20" src={tooltipimg} alt=""/>
                            </div>
                        </Col>
                    </Row>
                ) : (<></>)}
                {showCfaItem()}
                
                <ModalTemplates/>
            </Container>
    </>) : (<Container className='text-center'>
                        <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                        Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
                    </Container>)}
    </>)
}
 
export default ReleaseCfa;