import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'react-bootstrap';

import btnIcon from "../../img/icons/plus-white.svg"
import plus from "../../img/icons/plusGreen.svg";
import info from "../../img/icons/infoDocument.svg";

function ModalTemplates(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className="btn btn-primary d-flex align-items-center mt-4 mb-5"><img className="me-3" src={btnIcon} alt="Выпустить ЦФА"/> Выпустить ЦФА</button>

      <Modal
        className="cfa-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <h4 className='mb-3'>Выберите шаблон выпуска ЦФА</h4>
            <Card className='mb-3'>
                <CardBody>
                    <Row>
                        <Col lg={4}>
                          <div className='temlate'>
                              <div className='template-title pt-2'>Базовый шаблон</div>
                              <div className='template-links'>
                                  <div className="d-flex justify-content-between align-items-center">
                                      <Link to="/base-template"><img src={plus} alt="File Information"/></Link>
                                      <img src={info} alt="Анкета представителя Юридического лица"/>
                                  </div>
                              </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                          <div className='temlate'>
                              <div className='template-title pt-2'>Шаблон по готовым документам</div>
                              <div className='template-links'>
                                  <div className="d-flex justify-content-between align-items-center">
                                      <Link to="/base-template"><img src={plus} alt="File Information"/></Link>
                                      <img src={info} alt="Анкета представителя Юридического лица"/>
                                  </div>
                              </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                          <div className='temlate'>
                              <div className='template-title pt-2'>Индивидуальный шаблон</div>
                              <div className='template-links'>
                                  <div className="d-flex justify-content-between align-items-center">
                                      <Link to="/base-template"><img src={plus} alt="File Information"/></Link>
                                      <img src={info} alt="Анкета представителя Юридического лица"/>
                                  </div>
                              </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalTemplates;