import $api from "../http";
import {AxiosResponse} from 'axios';
import {ReleaseCfaResponse} from '../models/response/ReleaseCfaResponse';
import { IReleaseCfa } from "../models/IReleaseCfa";

export default class ReleaseCfaService {
    static async saveReleaseCfaService(
        email: string,
        user: string,
        emitName: string,
        releaseName: string,
        ticker: string,
        ownerType: string,
        countCfa: string,
        priceCfa: string,
        dateStartShowCfa: string,
        dateEndShowCfa: string,
        dateEmissionCfa: string,
        priceCompleateCfa: string,
        dateCompleateCfa: string,
        dateAdded: string,
        uploadReleaseCfa: File,
        isFormCopleated: string,
        removeuploadReleaseCfa: string,
        uploadUNEP: File,
        removeuploadUNEP: string,
        uploadRisks: File,
        removeuploadRisks: string,
        uploadSignature: File,
        removeuploadSignature: string,
        moreInfo: string
        ): Promise<AxiosResponse<ReleaseCfaResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('emitName', emitName);
        formData.append('releaseName', releaseName);
        formData.append('ticker', ticker);
        formData.append('ownerType', ownerType);
        formData.append('countCfa', countCfa);
        formData.append('priceCfa', priceCfa);
        formData.append('dateStartShowCfa', dateStartShowCfa);
        formData.append('dateEndShowCfa', dateEndShowCfa);
        formData.append('dateEmissionCfa', dateEmissionCfa);
        formData.append('priceCompleateCfa', priceCompleateCfa);
        formData.append('dateCompleateCfa', dateCompleateCfa);
        formData.append('dateAdded', dateAdded);
        formData.append('uploadReleaseCfa', uploadReleaseCfa);
        formData.append('isFormCopleated', isFormCopleated);
        formData.append('removeuploadReleaseCfa', removeuploadReleaseCfa);
        formData.append('uploadUNEP', uploadUNEP);
        formData.append('removeuploadUNEP', removeuploadUNEP);
        formData.append('uploadRisks', uploadRisks);
        formData.append('removeuploadRisks', removeuploadRisks);
        formData.append('uploadSignature', uploadSignature);
        formData.append('removeuploadSignature', removeuploadSignature);
        formData.append('moreInfo', moreInfo);

        return $api.post<ReleaseCfaResponse>('/saveReleaseCfa', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }

    static fetchCfa(): Promise<AxiosResponse<ReleaseCfaResponse[]>> {
        return $api.get<ReleaseCfaResponse[]>('/fetchCfa')
    }

    static getDetailCFA(emitID:string, cfaID: string): Promise<AxiosResponse<IReleaseCfa>> {
        return $api.post<IReleaseCfa>('/getCfaItem',{emitID, cfaID})
    }
}