import React, {FC, useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import mainImg from "../img/login/main.png";
import {Link, useParams} from 'react-router-dom';

const ResetPassword: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState<boolean>(false);
    const params = useParams();
    const [form_Data, set_Form_Data] = useState({
        password: '',
        confirmPassword: ''
    });
    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };
    const submitPassword = (e: any) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        let hash = params.hash;
        store.setError('');
        if (form.checkValidity() === true) {
            const password = form.elements.password.value;
            const confirmPassword = form.elements.confirmPassword.value;
            store.updatePassword(password, confirmPassword, hash);
            set_Validated(false);
            console.log(store.errorMessage);
            console.log(store.updatePasswordError);
            console.log(store.updatePasswordMesssage);
            setTimeout(() => {
                if (store.errorMessage === '' && !store.updatePasswordError) {
                    setShow(true);
                }
            }, 1000);
            
        } else {
            set_Validated(true);
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        window.location.href = "/login";
    }
    
    return (<Container>
            <Row className="form-container my-5 justify-content-between login-container">
                <Col className="mb-5 mb-lg-0" lg={4}>
                    <h4 className='mb-4'>Смена пароля</h4>
                    {store.errorMessage !== '' ?
                        <Alert variant="danger">{store.errorMessage}</Alert> : ''
                    }
                    <Form noValidate validated={validated}>
                        <Form.Group as={Col} className="mb-3" controlId="validatiodatedatePassword">
                            <Form.Control
                                type="password"
                                name="password"
                                onChange={chngFn}
                                value={form_Data.password}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Новый пароль'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validatiodatedateConfirmPassword">
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                onChange={chngFn}
                                value={form_Data.confirmPassword}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Подвердите новый пароль'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button className='mt-3' variant="primary" onClick={submitPassword}>Обновить пароль</Button>
                    </Form>
                </Col>
                <Col className="mb-5 mb-lg-0 form-container-img" lg={7}>
                    <img  className="w-100" src={mainImg} alt="Login page"/>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="restore-password-form">
                    <h4 className='congrats-header'>Пароль был успешно обновлен</h4>
                    <p className='congrats-text'>Перейдите на страницу авторизации</p>
                    <div className="text-center"><Link className='btn btn-primary' to="/login">Войти</Link></div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default observer(ResetPassword);