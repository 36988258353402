import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import envelope from "../../img/icons/Envelope.svg";
import gosuslugi from "../../img/icons/gosuslugi.svg";

function ModalGos(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {e.preventDefault(); setShow(true)};

  return (
    <>
    
      <Link className={props.class} onClick={handleShow}>
        <img className={props.classImg || 'me-3'} src={props.image || gosuslugi} alt={props.name || 'image'}/> {props.name}
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4>Совсем скоро будут доступны все возможности нашей платформы!</h4>
          <p>Но пока данное окно находится в разработке</p>
        </Modal.Body>
        <Modal.Footer>
          <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
          <div className='btn-outline-wrap-green mt-3 mb-4'>
          <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalGos;