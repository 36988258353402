import { React, Fragment, useState, useEffect } from "react";

import { Col, Container, Nav, Row } from "react-bootstrap";

import myData from './../../locale/ru.json';

import envelope from './../../img/footer/envelope.svg';
import notification from './../../img/footer/notification.svg';
import settings from './../../img/footer/settings-adjust.svg';
import logo from './../../img/logo/logo.png';

const Footer = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);
    return ( 
        <footer className="py-4">
            <Container>
                <Fragment>
                    <Row>
                        <Col lg={8}>
                            <div className="d-block d-lg-flex align-items-center text-center text-lg-left">
                                <div className="copy pb-4 pb-lg-0">
                                    <p>{myData.copySymbol} {year} {myData.copyright}</p>
                                </div>
                                <Nav className="justify-content-center justify-content-lg-start pb-4 pb-lg-0 ps-lg-4">
                                    <Nav.Link className="color-white" href="/privacyPolicy">{myData.nav.footer.privacyPolicy}</Nav.Link>
                                    <Nav.Link className="color-white" href="/termsService">{myData.nav.footer.termsService}</Nav.Link>
                                    <Nav.Link className="color-white" href="/investors">{myData.nav.footer.cookieSettings}</Nav.Link>
                                </Nav>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="d-flex d-flex align-items-center justify-content-center justify-content-lg-end">
                                <div className="setting-icons">
                                    <a className="footer-icon me-4" href="/"><img width="24" height="24" src={envelope} alt={myData.logo}/></a>
                                    <a className="footer-icon me-4" href="/"><img width="24" height="24" src={notification} alt={myData.logo}/></a>
                                    <a className="footer-icon me-4" href="/"><img width="24" height="24" src={settings} alt={myData.logo}/></a>
                                </div>
                                <div className="footer-logo ms-5"><img src={logo} width="90" className="d-inline-block align-top header-logo-img" alt={myData.logo} /></div>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            </Container>
        </footer>
    );
}
 
export default Footer;