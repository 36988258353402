import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const OrgAccountSubMenu = (props: { class1: string | undefined; class2: string | undefined; }) => {
    return ( <div className='organization-links'>
        <Container>
            <div className='organization-link-box'>
                <Link className={props.class1} to="/account" >Документы</Link>
                <Link className={props.class2} to="/information" >Информация</Link>
            </div>
        </Container>
    </div> );
}
 
export default OrgAccountSubMenu;