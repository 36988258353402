import { createBrowserRouter } from "react-router-dom"
import {Context} from "../index";
import { useContext } from "react"

import App from "../App"
import Home from "../pages/Home"
import About from "../pages/About"
import LoginForm from "../components/LoginForm"
import RegisterForm from "../components/RegisterForm"
import Account from "../pages/Account"
import ReleaseCfa from "../components/cfa/ReleaseCfa"
import Manufacturers from "../pages/Manufacturers"
import Investors from "../pages/Investors"
import Information from "../pages/Information"
import AccountCompanyForm from "../components/account/AccountCompanyForm"
import AccountRepresentativeForm from "../components/account/AccountRepresentativeForm"
import HarvestForm from "../components/account/informationForms/harvestForm"
import LandForm from "../components/account/informationForms/landForm"
import TechniqueForm from "../components/account/informationForms/techniqueForm"
import WarehouseForm from "../components/account/informationForms/warehouseForm"
import StocksForm from "../components/account/informationForms/stocksForm"
import DashboardCfa from "../components/cfa/DashboardCfa"
import MyCfa from "../components/cfa/MyCfa"
import TransactionHistory from "../components/cfa/TransactionHistory"
import BaseTemplate from "../components/cfa/BaseTemplate"
import ResetPassword from "../components/ResetPassword";
import CfaDetail from "../components/cfa/CfaDetail";
import ReleaseCfaDetail from "../components/cfa/ReleaseCfaDetail";
import MyCfaDetail from "../components/cfa/MyCfaDetail";


const LoginComponent = () => {
    const {store} = useContext(Context);
    let accountPage = <LoginForm/>
    if (store.isAuth) {
        accountPage = <Account/>;
    }
    return accountPage
}

const RegisterComponent = () => {
    const {store} = useContext(Context);
    let accountPage = <Account/>
    if (!store.isAuth) {
        accountPage = <RegisterForm/>;
    }
    return accountPage
}

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            { path: "", element: <Home/>},
            { path: "/about", element: <About/>},
            { path: "/registration", element: <RegisterComponent/>},
            { path: "/login", element: <LoginComponent/>},
            { path: "/account", element: <LoginComponent/>},
            { path: "/release-cfa", element: <ReleaseCfa/>},
            { path: "/manufacturers", element: <Manufacturers/>},
            { path: "/investors", element: <Investors/>},
            { path: "/information", element: <Information/>},
            { path: "/legal-entity", element: <AccountCompanyForm/>},
            { path: "/representative-entity", element: <AccountRepresentativeForm/>},
            { path: "/harvest", element: <HarvestForm/>},
            { path: "/land", element: <LandForm/>},
            { path: "/technique", element: <TechniqueForm/>},
            { path: "/warehouse", element: <WarehouseForm/>},
            { path: "/stocks", element: <StocksForm/>},
            { path: "/my-cfa", element: <MyCfa/>},
            { path: "/history-transaction", element: <TransactionHistory/>},
            { path: "/dashboard-cfa", element: <DashboardCfa/>},
            { path: "/base-template", element: <BaseTemplate/>},
            { path: "/resetPassword/:hash", element: <ResetPassword/>},
            { path: "/cfa/:emitID/:cfaID", element: <CfaDetail/>},
            { path: "/releaseCfaDetail/:emitID/:cfaID", element: <ReleaseCfaDetail/>},
            { path: "/myCfaDetail/:emitID/:cfaID", element: <MyCfaDetail/>}
        ]
    }
])
