import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { Alert, Button, Col, Container, Form, FormLabel, Modal, Row } from 'react-bootstrap';

import ModalDefault from './modals/ModalDefault';
import mainImg from "../img/login/main.png";
import { Navigate, Link} from 'react-router-dom';

const myData = require('../locale/ru.json');

const LoginForm: FC = () => {
    const {store} = useContext(Context);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authCode, setAuthCode] = useState<string>('');
    
    const restoreMesssage = store.restoreMesssage;
    const restoreError = store.restoreError;
    
    const Login = (e: any) => {
        e.preventDefault();
        store.login(email, password);
        setTimeout(() => {
            if (store.errorMessage === '' || store.errorMessage === 'Неверный код подтверждения') {
                setShowAuthModal(true);
                setSeconds(10);
                store.errorMessage = '';
            }
        }, 1000);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const showForgotPass = (e:any) => {
        e.preventDefault();
        store.errorMessage = '';
        setShow(true);
    };

    const [showAuthModal, setShowAuthModal] = useState(false);
    const handleCloseAuthModal = () => {
        setShowAuthModal(false);
        store.errorMessage = '';
    }
    const [validated, set_Validated] = useState<boolean>(false);

    const restorePassword = (e: any) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        if (form.checkValidity() === true) {
            const email = form.elements.restoreEmail.value;
            store.sendEmail(email);
            set_Validated(false);
            store.loader = true;
        } else {
            set_Validated(true);
        }
    }
    const [form_Data, set_Form_Data] = useState({
        restoreEmail: ''
    });

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const chngPaasword = (e: any) => {
        const {value } = e.target;
        e.preventDefault();
        setPassword(value);
        store.errorMessage = '';
    };

    const chngPaaswordEmail = (e: any) => {
        const {value } = e.target;
        e.preventDefault();
        setEmail(value);
        store.errorMessage = '';
    };

    const chngFnAuthCode = (e: any) => {
        const { value } = e.target;
        e.preventDefault();
        setAuthCode(value);
        if (value.length === 6) {
            store.loginAuth(email, password, value);
        }
    };

    const [ minutes, setMinutes ] = useState(0);
    const [seconds, setSeconds ] =  useState(0);
    
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    return (<><Container>
        <Row className="form-container my-5 justify-content-between login-container">
            <Col className="mb-5 mb-lg-0" lg={4}>
                {store.errorMessage !== '' && !show && !showAuthModal ?
                    <Alert variant="danger">{store.errorMessage}</Alert> : ''
                }
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-email">Адрес электронной почты</label></div>
                    <input
                        className='w-100'
                        onChange={chngPaaswordEmail}
                        value={email}
                        type="text"
                        placeholder='Email'
                        id="form-container-email"
                    />
                </div>
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-password">Пароль</label></div>
                    <input
                        className='w-100'
                        onChange={chngPaasword}
                        value={password}
                        type="password"
                        placeholder='Пароль'
                        id="form-container-password"
                    />
                </div>
                <Link to="/" onClick={showForgotPass}>Забыли пароль?</Link>
                <div className='form-container-item my-5'>
                    <Row className="justify-content-between align-items-center">
                        <Col lg={3}>
                            <button className="btn btn-primary w-100" disabled={ minutes === 0 && seconds === 0 ? false : true} onClick={Login}>
                                { minutes === 0 && seconds === 0
                                ? 'Войти'
                                : <span>Войти через {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> 
                            }
                            </button>
                            
                        </Col>
                        <Col lg={9}>
                            <p className='mb-0'><span className='me-2'>У Вас нет учетной записи?</span> <Link to='/registration'>{myData.buttons.registration}</Link></p>
                        </Col>
                    </Row>
                </div>

                <div className='form-container-links my-3 d-flex'>
                    <ModalDefault name="Вход через Госуслуги" classButton="btn-outline-wrap-gold me-3"/>
                    <ModalDefault name="Вход через ФНС" classButton="btn-outline-wrap-gold"/>
                </div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="restore-password-form">
                        {(restoreMesssage === '' || restoreError) ? (<>
                            <h4 className='congrats-header'>Забыли пароль?</h4>
                            <p className='congrats-text'>Для восстановления доступа к Личному кабинету и сброса пароля введите Ваш адрес электронной почты:</p>
                            <Form noValidate validated={validated}>
                                {store.errorMessage !== '' && show ?
                                    <Alert variant="danger">{store.errorMessage}</Alert> : ''
                                }
                                {restoreError?
                                    <Alert variant="danger">{restoreMesssage}</Alert> : ''
                                }
                                <Form.Group as={Col} className="mb-3" controlId="validatiodaterestoreEmailCfa">
                                    <Form.Control
                                        type="email"
                                        name="restoreEmail"
                                        onChange={chngFn}
                                        min={new Date().toISOString().slice(0, 10)}
                                        value={form_Data.restoreEmail}
                                        className='w-100 legal-form-1'
                                        required
                                        placeholder='Адрес электронной почты'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Введите корректные данные
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='text-center'>
                                    {store.loader ? (<div className='d-flex justify-content-center'><div className="loader"></div></div>) : (
                                        <Button className='mt-4 mb-5' variant="primary" onClick={restorePassword}>Сбросить пароль</Button>
                                    )}
                                    
                                </div>
                            </Form>
                        </>) : (<>
                            <p className="congrats-small mb-1">Проверьте почту!</p>
                            <h4 className='congrats-header mb-2'>Вам отправлено письмо<br/> для сброса пароля</h4>
                            <p className='congrats-text mb-5'>Перейдите по ссылке для восстановления доступа<br/> к Личному кабинету</p>
                            <div className='text-center'>
                                <Link onClick={handleClose} to="/login">Вернуться на страницу авторизации</Link>
                            </div>
                        </>)}
                        
                    </Modal.Body>
                </Modal>

                <Modal show={showAuthModal} onHide={handleCloseAuthModal} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className='congrats-header mb-2'>Подтвердите вход</h4>
                        <p className='congrats-text'>На ваш адрес электронной почты направлено письмо с кодом подтверждения</p>
                        <div className='form-container-item mb-5'>
                            <input
                                className='w-100'
                                value={email}
                                type='text'
                                placeholder='Пароль'
                                disabled
                            />
                        </div>
                        <Form.Group as={Col} className="mb-3 authCode" controlId="validatiodatedateauthCode">
                            <FormLabel className='required'>Код подтверждения</FormLabel>
                            <Form.Control
                                type="text"
                                name="authCode"
                                onChange={chngFnAuthCode}
                                maxLength={6}
                                minLength={6}
                                value={authCode}
                                className='w-100'
                                required
                                placeholder=''
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        {store.errorMessage !== '' && showAuthModal ?
                            <Alert variant="danger">{store.errorMessage}</Alert> : ''
                        }
                        <div className="text-center mb-4">
                        { minutes === 0 && seconds === 0
                            ? <Link onClick={Login} to="/">Отправить повторно</Link>
                            : <span>Отправить повторно через {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> 
                        }
                            
                        </div>
                    </Modal.Body>
                </Modal>


            </Col>
            <Col className="mb-5 mb-lg-0 form-container-img" lg={7}>
                <img  className="w-100" src={mainImg} alt="Login page"/>
            </Col>
        </Row>
        {store.isAuth ? <Navigate to="/account" replace={true} /> : ''}
        </Container></>
    );
};

export default observer(LoginForm);